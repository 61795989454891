/** @format */

import React from 'react';
import CTABanner from './CTABanners';
import {ctaBannerMapping} from './CTABanners/ctaBannerMapping';
import Image from './Image';
import TitleTextBanner from './TitleTextBanner';
import {badgeMapping} from './TitleTextBanner/badgeMapping';
import {titleTextBannerMapping} from './TitleTextBanner/titleTextBannerMapping';
import WYSIWYG from './WYSIWYG';
import {wysiwygMapping} from './WYSIWYG/wysiwygMapping';
import heroBannerMapping from './HeroBanner/heroBannerMapping';
import HeroBanner from './HeroBanner';
import ImageText from './ImageText';
import {imageTextMapping} from './ImageText/imageTextMapping';
import {spacerMapping} from './Spacer/spacerMapping';
import Spacer from './Spacer';
import CtaCards from './CtaCards';
import {ctaCardLinksMapping} from './CtaCards/ctaCardLinksMapping';
import {ctaCardsMapping} from './CtaCards/CTACardsMapping';
import {richTextCtaMapping} from './RichTextCta/richTextCtaMapping';
import RichTextCta from './RichTextCta';
import TestimonialWithImage from './TestimonialWithImage';
import {testimonialWithImageMapping} from './TestimonialWithImage/testimonialWithImageMapping';
import BioCards from './BioCards';
import bioCardsMapping from './BioCards/bioCardsMapping';
import gridMapping from './GridComponent/gridMapping';
import GridComponent from './GridComponent';
import tableMapping from './TableComponent/tableMapping';
import TableComponent from './TableComponent';
import accordionMapping from './Accordion/accordionMapping';
import Accordion from './Accordion';
import subNavigationMapping from './SubNavigation/subNavigationMapping';
import SubNavigation from './SubNavigation';
// import Form from './Form';
import formMapping from './Form/formMapping';
import PostGrid from './PostGrid';
import postGridMapping from './PostGrid/postGridMapping';
import RoomCarousel from './RoomCarousel';
import roomCarouselMapping from './RoomCarousel/roomCarouselMapping';
import FlexibleForm from './FlexibleForm';
import flexibleFormMapping from './FlexibleForm/flexibleFormMapping';

type PatternProps = {
	fieldGroupName: string;
	postType?: string;
	post?: any;
	page?: any;
	posts?: any;
	layout?: string;
	terms?: string[] | undefined;
	subdomain?: string;
};

function Patterns({fieldGroupName, post, terms, subdomain}: PatternProps) {
	if (!post) return null;

	const flexibleContentName = fieldGroupName.substr(
		fieldGroupName.indexOf(`_Pagelayout_FlexibleContent_`) +
			`_Pagelayout_FlexibleContent_`.length,
	);
	try {
		switch (flexibleContentName) {
			case 'Spacer': {
				const data = spacerMapping(post);
				return <Spacer {...data} />;
			}
			case 'HeroBanner': {
				const data = heroBannerMapping(post);
				return <HeroBanner {...data} subdomain={subdomain} />;
			}
			case 'Imagetext': {
				const data = imageTextMapping(post);
				return <ImageText {...data} subdomain={subdomain} />;
			}
			case 'CtaCards': {
				const data = ctaCardsMapping(post);
				return <CtaCards {...data} subdomain={subdomain} />;
			}
			case 'CtaCardLinks': {
				const data = ctaCardLinksMapping(post);
				return <CtaCards {...data} subdomain={subdomain} />;
			}
			case 'CtaBanner': {
				const data = ctaBannerMapping(post);
				return <CTABanner {...data} subdomain={subdomain} />;
			}
			case 'RichTextCta': {
				const data = richTextCtaMapping(post);
				return <RichTextCta {...data} />;
			}
			case 'TestimonialWithImage': {
				const data = testimonialWithImageMapping(post);
				return <TestimonialWithImage {...data} />;
			}
			case 'BioCards': {
				const data = bioCardsMapping(post);
				return <BioCards {...data} />;
			}
			case 'Grid': {
				const data = gridMapping(post);
				return <GridComponent {...data} />;
			}
			case 'Table': {
				const data = tableMapping(post);
				return <TableComponent {...data} />;
			}
			case 'Accordions': {
				const data = accordionMapping(post);
				return <Accordion {...data} />;
			}
			case 'SubNavigation': {
				const data = subNavigationMapping(post);
				return <SubNavigation {...data} />;
			}
			// case 'Forms': {
			// 	const data = formMapping(post);
			// 	return <Form forms={data} subdomain={subdomain} />;
			// }
			case 'Form': {
				const data = flexibleFormMapping(post);
				return <FlexibleForm data={data} subdomain={subdomain} />;
			}
			case 'PostGrid': {
				const data = postGridMapping(post);
				return <PostGrid subdomain={subdomain} />;
			}
			case 'RoomCarousel': {
				const data = roomCarouselMapping(post);
				return <RoomCarousel {...data} subdomain={subdomain} />;
			}
			//
			//
			//
			//
			//
			//
			// case "TitleTextBanner": {
			//     const data = titleTextBannerMapping(post);
			//     const badges = badgeMapping(terms);
			//     return <TitleTextBanner {...data} badges={badges} />;
			// }
			// case "Image": {
			//     return <Image {...data} />;
			// }

			// case "VideoLink": {
			//     const data = videoLinkMapping(post);
			//     return <VideoLink {...data} />;
			// }
			// case "Wysiwyg": {
			//     const data = wysiwygMapping(post);
			//     return <WYSIWYG {...data} />;
			// }

			default:
				// debugger;
				// console.error(flexibleContentName, ' is not yet defined');
				break;
		}
	} catch (e) {
		console.error(`Issue with pattern: ${flexibleContentName}`);
		console.error(e);
		throw e;
	}

	return null;
}

export default Patterns;

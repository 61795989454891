/** @format */

import {
	AspectRatio,
	Box,
	Container,
	Grid,
	GridItem,
	Heading,
	Link,
} from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import {textStyles} from '../../styles/js/textStyles';
import {WYSIWYG as WYSIWYGComponent} from '../../components/WYSIWYG';

export default ({title, cards}) => {
	if (!cards) return null;

	return (
		<Box bg='white' as='section' overflow='hidden' position='relative'>
			<Container maxW='container.xl' mx='auto'>
				<Box py={{base: '1.5rem', md: '2rem', lg: '4rem'}}>
					{title ? (
						<Heading
							{...textStyles.textxl}
							noOfLines={[1, 1, 2]}
							mb={{base: '3.5rem'}}>
							{title}
						</Heading>
					) : null}

					<Grid
						gap={{
							base: '1.5rem',
							md: '2rem',
							lg: '3.5rem',
						}}>
						{cards.map((card, i) => (
							<Grid
								key={i}
								templateColumns={{
									base: '1fr',
									lg: 'repeat(12, 1fr)',
								}}
								gap={{
									base: '1.5rem',
									md: '2rem',
									lg: '3.5rem',
								}}
								alignItems={{base: 'center'}}>
								<GridItem colSpan={{base: 'auto', lg: 4}} h={{base: '100%'}}>
									<AspectRatio ratio={3 / 2}>
										<Image
											src={card.image.src}
											alt={card.image.alt}
											layout='fill'
										/>
									</AspectRatio>
								</GridItem>
								<GridItem colSpan={{base: 'auto', lg: 8}}>
									{card.name ? (
										<Heading
											{...textStyles.headinglg}
											noOfLines={[1, 1, 2]}
											mb={{base: '1rem'}}>
											{card.name}
										</Heading>
									) : null}
									{card.jobTitle ? (
										<Heading {...textStyles.textsm} mb={{base: '1rem'}}>
											{card.jobTitle}
										</Heading>
									) : null}
									{card.email ? (
										<Heading {...textStyles.headingsm} mb={{base: '1rem'}}>
											<Link href={`mailto:${card.email}`} target='_blank'>
												{card.email}
											</Link>
										</Heading>
									) : null}
									{card.html ? <WYSIWYGComponent html={card.html} /> : null}
								</GridItem>
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

/** @format */

import {CalendarIcon} from '@chakra-ui/icons';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Container,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Radio,
	RadioGroup,
	Select,
	Spinner,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Textarea,
	useBreakpointValue,
} from '@chakra-ui/react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useRouter} from 'next/router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {textStyles} from '../../styles/js/textStyles';
import {Fields, Form as FlexibleFormMapping, Rows} from './flexibleFormMapping';
import {fonts} from '../../styles/js/fonts';

const emailValidation = (value: string) => {
	let error;
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
	if (!value) {
		error = 'Email is required';
	} else if (!emailRegex.test(value)) {
		error = 'Invalid email address';
	}
	return error;
};

const generateInitialValues = (rows: Rows[]): {[key: string]: string} => {
	let initialValues = {};

	rows.forEach(row => {
		row.fields.forEach(field => {
			initialValues[field.title || ''] = ''; // Assuming title is unique and non-empty for now
		});
	});

	return initialValues;
};

const FlexibleForm = ({
	data,
	subdomain,
}: {
	data: FlexibleFormMapping;
	subdomain: string;
}) => {
	const {tabs, sender} = data;
	const router = useRouter();
	// Function to check if a query parameter exists using router
	const hasQueryParam = param => {
		return router.query && Object.hasOwnProperty.call(router.query, param);
	};

	const submitForm = async (values, actions, sender) => {
		//generate an email subject from the full slug
		const subject = router.asPath


		let subjectFormatted = subject.replace(/\//g, '-');

		//remove the first dash
		if (subjectFormatted.charAt(0) === '-') {
			subjectFormatted = subjectFormatted.substr(1);
		}


		//come up with a default if the subject is empty
		if (subjectFormatted === '') {
			subjectFormatted = 'New Harlaxton Inquiry';
		}

		//convert all value keys to camelCase removing spaces and special characters and convert to lowercase
		let valuesCamelCase;
		Object.keys(values).forEach(key => {
			const newKey = key.replace(/[^a-zA-Z0-9 ]/g, '');
			const newKeyCamelCase = newKey
				.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
					return index === 0 ? word.toLowerCase() : word.toUpperCase();
				})
				.replace(/\s+/g, '');
			valuesCamelCase = {
				...valuesCamelCase,
				[newKeyCamelCase]: values[key],
			};
		});

		let emailTo;
		if (valuesCamelCase?.email) {
			emailTo = valuesCamelCase.email;
		} else if (valuesCamelCase?.emailAddress) {
			emailTo = valuesCamelCase.emailAddress;
		}

		// Adding Logic for email sent/notsent
		// Your current path
		const currentPath = router.asPath.split('?')[0]; // Gets the path without the query string
		// Decide whether to use '&' or '?'
		const separator = currentPath.includes('?') ? '&' : '?';

		try {
			const response = await fetch('/api/sendEmail', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					values: valuesCamelCase,
					sender,
					subjectFormatted,
					emailTo,
				}),
			});

			const data = await response.json();
			actions.setSubmitting(false);
			// Check if 'submitted' query parameter already exists
			if (!hasQueryParam('submitted')) {
				// Perform the URL replacement
				router.replace(`${currentPath}${separator}submitted=true`, undefined, {
					shallow: true,
				});
			}
		} catch (error) {
			console.error('Error:', error);
			actions.setSubmitting(false);
			// Check if 'submitted' query parameter already exists
			if (!hasQueryParam('submitted')) {
				// Perform the URL replacement
				router.replace(`${currentPath}${separator}submitted=false`, undefined, {
					shallow: true,
				});
			}
		}
	};

	const subdomainTheme = () => {
		const isEvents = router.asPath.split('/')[1] === 'events';

		if(isEvents) return 'gold';

		switch (subdomain) {
			case 'college':
				return 'aqua';
			case 'garden':
				return 'pine';
			default:
				return 'maroon';
		}
	};

	// Generate initial values for all tabs
	let allInitialValues = {};
	tabs.forEach(tab => {
		const initialValues = generateInitialValues(tab.rows);
		allInitialValues = {...allInitialValues, ...initialValues};
	});

	if (!router.isReady) {
		return (
			<Stack
				direction='row'
				spacing={4}
				justifyContent={'center'}
				margin='5rem'>
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='xl'
				/>
			</Stack>
		);
	}

	return (
		<Container position='relative' maxW='container.xl' mx='auto'>
			<Box>
				{typeof router.query.submitted !== 'undefined' && (
					<>
						<Headings tabs={tabs} />
						<Alert
							status={router.query.submitted == 'true' ? 'success' : 'error'}
							variant='subtle'
							flexDirection='column'
							alignItems='center'
							justifyContent='center'
							textAlign='center'
							height='200px'
							fontFamily={fonts.copernicusGalaxyBook}>
							<AlertIcon boxSize='40px' mr={0} />
							<AlertTitle mt={4} mb={1} fontSize='lg' pb='8px'>
								{router.query.submitted == 'true'
									? 'Form submitted!'
									: 'Oops something went wrong.'}
							</AlertTitle>
							<AlertDescription maxWidth='sm'>
								{router.query.submitted == 'true'
									? 'Thank you for submitting your enquiry. Our team will get back to you very soon.'
									: 'We couldn’t process your enquiry, please try again later.'}
							</AlertDescription>
						</Alert>
					</>
				)}

				{router.query.submitted ? null : (
					<Formik
						initialValues={allInitialValues}
						validate={values => {
							let errors = {};
							tabs.forEach(tab => {
								tab.rows.forEach(row => {
									row.fields.forEach((field: Fields) => {
										if (field.required && !values[field.title || '']) {
											errors[field.title || ''] = `${field.title} is required`;
										}
										if (field.validation === 'email') {
											const error = emailValidation(values[field.title || '']);
											if (error) {
												errors[field.title || ''] = error;
											}
										}
									});
								});
							});
							return errors;
						}}
						onSubmit={async (values, actions) => {
							console.log(values);
							await submitForm(values, actions, sender);
						}}>
						{() => (
							<Form>
								{tabs.length > 1 ? (
									<>
										<Tabs
											variant='enclosed'
											colorScheme={subdomainTheme()}
											mb={16}>
											<TabList mb='4'>
												{tabs?.map((tab, index) => (
													<Tab
														key={index}
														borderRadius={0}
														bg='white'
														_selected={{color: 'white', bg: `${subdomainTheme()}.500`}}
														{...textStyles.headingmd}
														>
														{tab.formInfo.heading}
													</Tab>
												))}
											</TabList>

											<TabPanels>
												{tabs?.map((tab, index) => {
													const {formInfo, rows} = tab;
													return (
														<TabPanel key={index}>
															<Box mb={16}>
																<Text {...textStyles.textxl} mb={8}>
																	{formInfo.heading}
																</Text>
																<Text {...textStyles.textsm}>
																	{formInfo.subheading}
																</Text>
															</Box>
															{rows.map((item, rowIndex) => {
																return getDynamicRow(
																	item,
																	rowIndex,
																	subdomainTheme(),
																);
															})}
														</TabPanel>
													);
												})}
											</TabPanels>
										</Tabs>
									</>
								) : (
									<>
										<Headings tabs={tabs} />
										{tabs[0].rows.map((item, rowIndex) => {
											return getDynamicRow(item, rowIndex, subdomainTheme());
										})}
									</>
								)}
								<Button
									mt={8}
									type='submit'
									colorScheme={subdomainTheme()}
									px={8}
									{...textStyles.displaysm}>
									submit
								</Button>
							</Form>
						)}
					</Formik>
				)}
			</Box>
		</Container>
	);
};

const Headings = ({tabs}) => {
	return (
		<Box mb={16}>
			<Text {...textStyles.textxl} mb={8}>
				{tabs[0].formInfo.heading}
			</Text>
			<Text {...textStyles.textsm}>{tabs[0].formInfo.subheading}</Text>
		</Box>
	);
};

const TextField = ({data, name}) => {
	const {title, placeholder, required} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, meta}) => (
					<>
						<Input
							{...field}
							placeholder={placeholder}
							isInvalid={meta.touched && meta.error}
							{...textStyles.textsm}
						/>
						<ErrorMessage
							name={name}
							component='div'
							style={{color: 'red'}}
							className='form-error'
						/>
					</>
				)}
			</Field>
		</Box>
	);
};

const SelectField = ({data, name}) => {
	const {title, placeholder, required, choices} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, meta}) => (
					<>
						<Select
							{...field}
							placeholder={placeholder}
							isInvalid={meta.touched && meta.error}
							{...textStyles.textsm}>
							{choices?.map((choice, index) => (
								<option key={index} value={choice.choice}>
									{choice.choice}
								</option>
							))}
						</Select>
						<ErrorMessage name={name} component='div' style={{color: 'red'}} />
					</>
				)}
			</Field>
		</Box>
	);
};

const RadioField = ({data, name, subdomainTheme}) => {
	const {title, required, choices} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, form, meta}) => (
					<>
						<RadioGroup
							value={field.value}
							onChange={val => form.setFieldValue(name, val)}>
							<Stack direction='row'>
								{choices?.map((choice, index) => (
									<Radio
										key={index}
										value={choice.choice}
										colorScheme={subdomainTheme}>
										<Text {...textStyles.textsm}>{choice.choice}</Text>
									</Radio>
								))}
							</Stack>
						</RadioGroup>
						<ErrorMessage name={name} component='div' style={{color: 'red'}} />
					</>
				)}
			</Field>
		</Box>
	);
};

const TextAreaField = ({data, name}) => {
	const {title, placeholder, required} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, meta}) => (
					<>
						<Textarea
							{...field}
							placeholder={placeholder}
							isInvalid={meta.touched && meta.error}
							{...textStyles.textsm}
						/>
						<ErrorMessage name={name} component='div' style={{color: 'red'}} />
					</>
				)}
			</Field>
		</Box>
	);
};

const NumberInputFieldComponent = ({data, name}) => {
	const {title, placeholder, required} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, form, meta}) => {
					const {setFieldValue} = form;
					return (
						<>
							<NumberInput
								value={field.value}
								onChange={value => setFieldValue(name, value)}
								isInvalid={meta.touched && meta.error}
								{...textStyles.textsm}>
								<NumberInputField placeholder={placeholder} />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
							<ErrorMessage
								name={name}
								component='div'
								style={{color: 'red'}}
							/>
						</>
					);
				}}
			</Field>
		</Box>
	);
};

const DatePickerField = ({data, name}) => {
	const {title, required} = data;

	return (
		<Box flexGrow='1' width={{base: '100%', lg: 'auto'}}>
			<Flex mb={2} alignItems='center'>
				<Text {...textStyles.textsm}>{title}</Text>
				{required && (
					<Text ml={2} color='red.500'>
						*
					</Text>
				)}
			</Flex>
			<Field name={name}>
				{({field, form, meta}) => {
					const {setFieldValue} = form;
					return (
						<>
							<InputGroup zIndex={10000} width='100%' flexGrow='1'>
								<Input
									as={DatePicker}
									selected={field.value}
									onChange={val => setFieldValue(name, val)}
									dateFormat='MM/dd/yyyy'
									isInvalid={meta.touched && meta.error}
									{...textStyles.textsm}
									width='100%'
									flexGrow='1'
								/>
								<InputRightAddon children={<CalendarIcon color='gray.400' />} />
							</InputGroup>
							{meta.touched && meta.error ? (
								<div style={{color: 'red'}}>{meta.error}</div>
							) : null}
						</>
					);
				}}
			</Field>
		</Box>
	);
};

export default FlexibleForm;
function getDynamicRow(item: Rows, rowIndex: number, subdomainTheme = '') {
	const {fields, title} = item;
	return (
		<>
			{title && (
				<Text {...textStyles.headingmd} mb={8} key={rowIndex}>
					{title}
				</Text>
			)}
			<Flex
				key={rowIndex}
				flexWrap='wrap'
				gap={8}
				direction={useBreakpointValue({base: 'column', lg: 'row'}, {ssr: true})}
				alignItems='flex-start'
				mb={8}>
				{fields?.map((item: Fields, fieldIndex) => {
					return getDynamicField(item, fieldIndex, subdomainTheme);
				})}
			</Flex>
		</>
	);
}

function getDynamicField(
	item: Fields,
	fieldIndex: number,
	subdomainTheme = '',
) {
	switch (item.type) {
		case 'text':
			return <TextField key={fieldIndex} name={item.title} data={item} />;
		case 'select':
			return <SelectField key={fieldIndex} name={item.title} data={item} />;
		case 'radio':
			return (
				<RadioField
					key={fieldIndex}
					name={item.title}
					data={item}
					subdomainTheme={subdomainTheme}
				/>
			);
		case 'textarea':
			return <TextAreaField key={fieldIndex} name={item.title} data={item} />;
		case 'numberinput':
			return (
				<NumberInputFieldComponent
					key={fieldIndex}
					name={item.title}
					data={item}
				/>
			);
		case 'datepicker':
			return <DatePickerField key={fieldIndex} name={item.title} data={item} />;
		default:
			return <TextField key={fieldIndex} name={item.title} data={item} />;
	}
}

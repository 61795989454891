/** @format */

import {
	Box,
	Container,
	Flex,
	Stack,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {CTAHeading, Ctas, CTASubtitle} from '..';
import {getDomainFontFamily} from '../../../utils';
import {fonts} from '../../../styles/js/fonts';
import {textStyles} from '../../../styles/js/textStyles';

const LeftAlignedCTABanner = ({
	title,
	isFullBleed,
	subtitles,
	includeBackgroundImage,
	backgroundImage,
	backgroundColour,
	textColor,
	ctas,
	subdomain,
}) => {
	const {isOpen, onOpen, onClose} = useDisclosure();
	const view = useBreakpointValue({base: 'mobile', md: 'desktop'}, {ssr: true});
	const ctaAlignment =
		view === 'mobile' ? 'flex-start' : ctas.length > 1 ? 'flex-end' : 'center';
	const border = isFullBleed ? 'none' : 'none';
	const overlay =
		textColor === 'light'
			? 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0))'
			: 'linear-gradient(90deg, rgba(0, 2, 64, 0.55) 0%, rgba(0, 2, 64, 0.55))';
	const bgImage =
		includeBackgroundImage && backgroundImage?.src
			? {
					backgroundImage: `${overlay}, url(${backgroundImage.src})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
			  }
			: null;
	const padding = {
		base: '2.5rem 1.5rem',
		md: '2.5rem 2rem',
		lg: '4rem',
	};
	const domainFontFamily = getDomainFontFamily(subdomain);
	const headingStylesSelected = {
		...useBreakpointValue(
			{base: textStyles.textxl, lg: textStyles.text2Xl},
			{ssr: true},
		),
	};

	const content = (
		<>
			<Flex
				direction='column'
				sx={{'> *:last-child': {mb: '0'}}}
				alignItems='flex-start'
				flex={view === 'mobile' ? '1' : '2'}>
				<CTAHeading
					title={title}
					domainFontFamily={domainFontFamily}
					headingStylesSelected={headingStylesSelected}
				/>

				{subtitles?.map((subtitle, i) => (
					<CTASubtitle
						key={i}
						text={subtitle}
						domainFontFamily={fonts.copernicusGalaxyBook}
					/>
				))}
			</Flex>

			{ctas && ctas.length > 0 && (
				<Flex
					flexShrink={0}
					justifyContent={ctaAlignment}
					pt={view === 'mobile' ? '8' : '0'}
					marginLeft={view === 'mobile' ? '0' : '6'}>
					<Stack
						spacing='3'
						flex={{base: '1', sm: '0'}}
						direction={{base: 'column', sm: 'row'}}
						justify='center'
						className='button-group-stack'>
						<Ctas
							ctas={ctas}
							onOpen={onOpen}
							isOpen={isOpen}
							onClose={onClose}
							subdomain={subdomain}
						/>
					</Stack>
				</Flex>
			)}
		</>
	);

	if (isFullBleed)
		return (
			<Flex
				my={{base: '4rem'}}
				backgroundColor={backgroundColour}
				p={padding}
				direction={view === 'mobile' ? 'column' : 'row'}
				alignItems={view === 'mobile' ? 'stretch' : 'center'}
				flexWrap={'wrap'}
				{...bgImage}>
				{content}
			</Flex>
		);

	return (
		<Box
			className='CTA-banner'
			as='section'
			overflow='hidden'
			position='relative'
			py={{base: 12, md: 16}}>
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Flex
					backgroundColor={backgroundColour}
					layerStyle={border}
					p={padding}
					justifyContent='space-between'
					direction={view === 'mobile' ? 'column' : 'row'}
					alignItems={view === 'mobile' ? 'stretch' : 'center'}
					flexWrap={'wrap'}
					{...bgImage}>
					{content}
				</Flex>
			</Container>
		</Box>
	);
};

export default LeftAlignedCTABanner;

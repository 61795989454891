/** @format */

import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

let client: ApolloClient<NormalizedCacheObject>;

/**
 * getApolloClient
 */

export function getApolloClient(subdomain) {
	// if (!client || subdomain) {
	client = _createApolloClient(subdomain);
	// }
	// console.log('DBG getApolloClient client - ', client);

	return client;
}

/**
 * createApolloClient
 */

export function _createApolloClient(subdomain) {
	// return new ApolloClient({
	// 	link: authLink.concat(httpLink),
	// 	cache: new InMemoryCache(),
	// });

	let DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
	// console.log('DBG _createApolloClient subdomain - ', subdomain);
	switch (subdomain) {
		case 'college':
			DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_COLLEGE;
			break;
		case 'garden':
			DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_GARDEN;
			break;
		case 'tickets':
			DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_TICKETS;
			break;
		default:
			DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
			break;
	}
	// console.log('DBG _createApolloClient DOMAIN_API_URL - ', DOMAIN_API_URL);

	return new ApolloClient({
		link: authLink.concat(createHttpLink({uri: DOMAIN_API_URL})),
		cache: new InMemoryCache(),
	});
}

// const httpLink = createHttpLink({
// 	uri: process.env.NEXT_PUBLIC_WORDPRESS_API_URL,
// });

const authLink = setContext((_, {headers}) => {
	// get the authentication token from local storage if it exists
	const token = process.env.WORDPRESS_AUTH_REFRESH_TOKEN;
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

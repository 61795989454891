/** @format */

import React from 'react';
import {Box} from '@chakra-ui/react';

export function WYSIWYG({html, variant = '', ...containerProps}) {
	/* style class will need a corresponding global style config object in theme.js */
	const style = variant ? `WYSIWYG--${variant}` : `WYSIWYG--default`;
	const WYSIWYG = props => <Box className={`WYSIWYG ${style}`} {...props} />;

	return (
		<>
			<WYSIWYG dangerouslySetInnerHTML={html} {...containerProps} />
		</>
	);
}

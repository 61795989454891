import {
    AspectRatio,
    Box,
    Container,
    Grid,
    GridItem,
    Heading,
} from "@chakra-ui/react";
import Image from "next/image";
import React from "react";
import { WYSIWYG as WYSIWYGComponent } from "../../components/WYSIWYG";
import { colors } from "../../styles/js/colors";
import { textStyles } from "../../styles/js/textStyles";

export default ({ title, gridList }) => {
    return (
        <Box bg="white" as="section" overflow="hidden" position="relative">
            <Container
                position="relative"
                maxW="container.xl"
                mx="auto"
                px={{ base: '1.5rem', md: 4 }}
                my={{ base: "4rem" }}
            >
                {title ? (
                    <Heading
                        {...textStyles.headinglg}
                        noOfLines={[1, 1, 2]}
                        mb={{ base: "3.5rem" }}
                    >
                        {title}
                    </Heading>
                ) : null}

                <Grid
                    templateColumns={{
                        base: "repeat(1,1fr)",
                        md: "repeat(2, 1fr)",
                        lg: "repeat(3, 1fr)",
                    }}
                    gap={{ base: "3rem", md: "2rem", lg: "3.5rem" }}
                >
                    {gridList.map((gridItem, i) => {
                        return (
                            <GridItem key={`${i}-grid-item`}>
                                <Grid>
                                    <GridItem>
                                        <AspectRatio ratio={3 / 2}>
                                            <Image
                                                src={gridItem.image.src}
                                                alt={gridItem.image.alt}
                                                fill
                                                style={{
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </AspectRatio>
                                    </GridItem>
                                    <GridItem>
                                        {gridItem.title ? (
                                            <Heading
                                                {...textStyles.textxl}
                                                noOfLines={[1, 1, 2]}
                                                mt={6}
                                                mb={1.5}
                                                color={{
                                                    base: colors.grey[700],
                                                }}
                                            >
                                                {gridItem.title}
                                            </Heading>
                                        ) : null}

                                        {gridItem.html ? (
                                            <WYSIWYGComponent
                                                html={gridItem.html}
                                            />
                                        ) : null}

                                        {gridItem.children ? (
                                            <>{gridItem.children}</>
                                        ) : null}
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        );
                    })}
                </Grid>
            </Container>
        </Box>
    );
};

/** @format */

import {
	ButtonGroup,
	Button,
	GridItem,
	Container,
	Grid,
	Box,
	Stack,
} from '@chakra-ui/react';
import React from 'react';
import {WYSIWYG as WYSIWYGComponent} from '../../components/WYSIWYG';

const RichTextCta = ({html, buttons, columnOffset, container = true}: any) => {
	const RichTextCtaChild = ({html, buttons, columnOffset, container}: any) => {
		return (
			<Grid
				pt={{ base: '8rem', md: '8rem' }}
				pb={{ base: '4rem', md: '4rem' }}
				templateColumns={{
					base: '1fr',
					sm: 'repeat(12, 1fr)',
				}}>
				<GridItem
					display='grid'
					gridColumn={{
						base: '1 / 12',
						lg: `${columnOffset} / ${12 - columnOffset}`,
					}}
					className='rich-text-cta item-column'>
					<WYSIWYGComponent html={html} />
					{buttons && buttons.length ? (
						<Box pt={{base: '2.5rem'}}>
							<ButtonGroup
								as={Stack}
								variant='outline'
								spacing='6'
								direction={['column', 'row']}
								w={{base: '100%', md: 'auto'}}>
								{buttons.map((button, j) => (
									<Button
										marginInlineStart={{base: '0'}}
										key={`${j}-text-button`}
										size='lg'
										variant={button.style}
										justifyContent='center'
										flexShrink={0}
										colorScheme={button.colorScheme || ''}
										as='a'
										href={button.url}
										target={button.target}>
										{button.title}
									</Button>
								))}
							</ButtonGroup>
						</Box>
					) : null}
				</GridItem>
			</Grid>
		);
	};

	return (
		<Box as='section' overflow='hidden' position='relative'>
			{container ? (
				<Container maxW='container.xl' mx='auto'>
					<RichTextCtaChild
						html={html}
						buttons={buttons}
						columnOffset={columnOffset}
						container={container}
					/>
				</Container>
			) : (
				<RichTextCtaChild
					html={html}
					buttons={buttons}
					columnOffset={columnOffset}
					container={container}
				/>
			)}
		</Box>
	);
};

export default RichTextCta;

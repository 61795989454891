/** @format */

import {Box, Container, Grid, GridItem} from '@chakra-ui/react';
import React, {ReactElement} from 'react';
import Image from '../../components/Image';
import {colors} from '../../styles/js/colors';
import {getDomainFontFamily} from '../../utils';
import LogoIcon from '../LogoIcon';
import TextComponent from '../TextComponent';
import {fonts} from '../../styles/js/fonts';

function ImageText({
	textComponent,
	image,
	textAlignment,
	shieldLogo,
	subdomain,
}): ReactElement {
	if (!image) return null;

	const isTextOnLeft = textAlignment === 'left';

	const imageCols = 8 + 1;
	const textCols = 6 + 1;
	const imageColOverlap = 2;
	const gridColEnd = 12 + 1;
	const imageTextGridItemProps = {
		textGridItemProps: {
			gridColumn: {
				base: 'auto',
				lg: isTextOnLeft ? `1 / ${textCols}` : `${textCols} / ${gridColEnd}`,
			},
			gridRow: {base: 'auto', lg: isTextOnLeft ? '1 / 12' : '2 / 11'},
			zIndex: '1',
			pb: {base: '2rem'},
		},
		imageGridItemProps: {
			gridColumn: {
				base: 'auto',
				lg: isTextOnLeft
					? `${textCols - imageColOverlap} / ${gridColEnd}`
					: `1 / ${imageCols}`,
			},
			gridRow: {base: 'auto', lg: isTextOnLeft ? '2 / 11' : '1 / 12'},
			alignSelf: 'stretch',
			// position: "relative",
		},
	};

	// Check if all buttons have the same colorScheme
	const isAllSame = textComponent?.buttons
		.map(button => button.colorScheme)
		.every((val, i, arr) => val === arr[0]);
	const colorScheme = textComponent?.buttons[0]?.colorScheme;

	return (
		<Box
			as='section'
			overflow='hidden'
			position='relative'
			py={{base: '48px', md: '64px'}}
			maxHeight={{base: 'auto'}}>
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Grid
					templateColumns={{
						base: '1fr',
						lg: 'repeat(12, 1fr)',
					}}
					alignItems='center'>
					<GridItem
						className='text-container'
						{...imageTextGridItemProps.textGridItemProps}>
						<Box bg='white'>
							<TextComponent
								title={textComponent.title}
								titleCategory={textComponent.titleCategory}
								subtitles={textComponent.subtitles}
								buttons={textComponent.buttons}
								domainFontFamily={fonts.eDNimpkishRegular}
								titleDomainFontFamily={fonts.copernicusGalaxyBook}
								subtitleDomainFontFamily={fonts.copernicusGalaxyBook}
								padding={{
									// base: '3.5rem 0',
									base: '0',
									lg: isTextOnLeft
										? '3.5rem 3.5rem 3.5rem 0'
										: '3.5rem 0 3.5rem 3.5rem',
								}}
							/>
						</Box>
					</GridItem>
					<GridItem
						pb={{base: '40px'}} // need this for the logo to show up
						className='image-container'
						{...imageTextGridItemProps.imageGridItemProps}>
						{image && (
							<Box position='relative'>
								<Image
									src={image.src}
									alt={image.alt}
									width={856}
									height={200}
									style={{
										objectFit: 'cover',
										minHeight: '642px',
									}}
								/>
								{shieldLogo ? (
									<Box
										position='absolute'
										bottom={'-40px'}
										zIndex={3}
										right={isTextOnLeft ? '40px' : undefined}
										left={isTextOnLeft ? undefined : '40px'}>
										{isAllSame && colorScheme ? (
											<LogoIcon fill={colors[colorScheme]['500']} />
										) : null}
										{/* <Image
                                            src="/images/LogoShield.svg"
                                            alt="logo_alt_here"
                                            width={100}
                                            height={100}
                                            style={{
                                                objectFit: "none",
                                                width: "auto",
                                                height: "auto",
                                            }}
                                        /> */}
									</Box>
								) : null}
							</Box>
						)}
					</GridItem>
				</Grid>
			</Container>
		</Box>
	);
}

export default ImageText;

/** @format */

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Container,
	Grid,
	GridItem,
	useBreakpoint,
} from '@chakra-ui/react';
import {WYSIWYG as WYSIWYGComponent} from '../../components/WYSIWYG';

export default ({accordions}) => {
	return (
		<Box bg='white' as='section' overflow='hidden' position='relative'>
			<Container position='relative' maxW='container.xl' mx='auto'>
				<Accordion allowMultiple>
					{accordions &&
						accordions.map((accordionItem, index) => (
							<AccordionBody
								key={`${accordionItem.title}-${index}`}
								accordionItem={accordionItem}
							/>
						))}
				</Accordion>
			</Container>
		</Box>
	);
};

const AccordionBody = ({accordionItem}) => {
	const {title, wysiwygs} = accordionItem;

	return (
		<AccordionItem>
			<AccordionButton>
				<Box flex='1' textAlign='left'>
					{title}
				</Box>
				<AccordionIcon />
			</AccordionButton>
			<AccordionPanel>
				<DynamicGridComponent wysiwygs={wysiwygs} />
			</AccordionPanel>
		</AccordionItem>
	);
};

const DynamicGridComponent = ({wysiwygs}) => {
	if (!wysiwygs) return null;
	const breakpoint = useBreakpoint({ssr: true});

	// set number of columns based on the number of wysiwygs
	const numColumns =
		breakpoint === 'base' || breakpoint === 'sm' ? 1 : wysiwygs.length || 1;

	return (
		<Grid
			templateColumns={`repeat(${numColumns}, 1fr)`}
			gap={{base: '0', sm: '3.5rem'}}>
			{wysiwygs.map((wysiwyg, index) => (
				<GridItem key={index}>
					<WYSIWYGComponent html={wysiwyg} />
				</GridItem>
			))}
		</Grid>
	);
};

/** @format */

import {Box, Container, Fade} from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import {getDomainFontFamily} from '../../utils';
import TextComponent from '../TextComponent';
import {colors} from '../../styles/js/colors';

const HeroBannerChildren = ({
	backgroundType,
	backgroundColor,
	subtitles,
	title,
	titleCategory,
	image,
	video,
	videoThumbnail,
	fullWidth,
	subdomain,
	height,
	defaultBackgroundColor,
	domainFontFamily,
	...props
}: any) => {
	return (
		<Box position='relative'>
			{/* Background Image */}
			{backgroundType === 'image' && (
				<Box position='relative' width='100%' height={height}>
					<Image
						src={image.src}
						alt={image.alt}
						fill
						style={{objectFit: 'cover'}}
						priority
					/>
				</Box>
			)}

			{backgroundType === 'video' && (
				<Box position='relative' width='100%' className='hero-video'>
					<Fade in={true}>
						<video
							width='auto'
							height={height}
							muted
							loop
							autoPlay={true}
							playsInline
							style={{
								pointerEvents: 'none',
								objectFit: 'cover',
								width: '100%',
								height,
								display: 'block',
							}}
							poster={videoThumbnail.src}>
							{video.video.mediaItemUrl.includes('mp4') ? (
								<>
									<source
										src={`${video.video.mediaItemUrl}#t=00:00:00.001`}
										type='video/mp4'
									/>
								</>
							) : (
								<>
									<source src={`${video.video.mediaItemUrl}#t=00:00:00.001`} />
									<img
										src={videoThumbnail.src}
										title='Your browser does not support the <video> tag'
									/>
								</>
							)}
						</video>
					</Fade>
				</Box>
			)}
			{/* Overlay */}
			{(backgroundType === 'video' || backgroundType === 'image') && (
				<Box
					position='absolute'
					top={0}
					left={0}
					right={0}
					bottom={0}
					bg={defaultBackgroundColor}
					display='flex'
					alignItems='center'
					color='white'>
					<TextComponent
						heroComponent={true}
						title={title}
						titleCategory={titleCategory}
						subtitles={subtitles}
						domainFontFamily={domainFontFamily}
					/>
				</Box>
			)}

			{backgroundType === 'text' && (
				<Box
					position='relative'
					width='100%'
					height={height}
					bg={backgroundColor}
					display='flex'
					alignItems='center'
					color='white'>
					<TextComponent
						heroComponent={true}
						title={title}
						titleCategory={titleCategory}
						subtitles={subtitles}
						domainFontFamily={domainFontFamily}
					/>
				</Box>
			)}
		</Box>
	);
};

const HeroBanner = ({...props}) => {
	const {backgroundType, fullWidth, subdomain} = props;
	let defaultBackgroundColor = colors.blackAlpha.blackAlpha_500; //"rgba(26, 26, 26, 0.36)";
	if (subdomain === 'college' && backgroundType === 'image') {
		defaultBackgroundColor = '';
	}
	const domainFontFamily = getDomainFontFamily(subdomain);

	let height = '720px';
	if (backgroundType === 'image') height = '560px';
	if (backgroundType === 'video') height = '720px';
	if (backgroundType === 'text') height = '560px';

	return (
		<Box position='relative'>
			{fullWidth ? (
				<HeroBannerChildren
					{...props}
					height={height}
					defaultBackgroundColor={defaultBackgroundColor}
					domainFontFamily={domainFontFamily}
				/>
			) : (
				<Container maxW='container.xl'>
					<HeroBannerChildren
						{...props}
						height={height}
						defaultBackgroundColor={defaultBackgroundColor}
						domainFontFamily={domainFontFamily}
					/>
				</Container>
			)}
		</Box>
	);
};

export default HeroBanner;
